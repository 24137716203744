<template>
<div>
    <div class="card">
        <!-- Logo -->
        <div class="card-header pt-4 pb-4 text-center ">
            <span><img src="@/assets/images/logo/cover_photo_1.png" alt="" height="52"></span>
        </div>

        <div class="card-body p-4">         
            <form action="#">
                <div class="text-center w-75 m-auto">
                    <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Вход</h4>
                    <p class="text-muted mb-4">Введите адрес электронной почты и пароль для входа.</p>
                </div>

                <div class="form-group">
                    <label for="emailaddress">Email адрес</label>
                    <input class="form-control" type="email" required placeholder="Введите Ваш email" v-model="form.email">
                </div>

                <div class="form-group">
                    <!-- <router-link :to="{name: 'Recover'}" class="text-muted float-right"><small>Забыли пароль?</small></router-link> -->
                    <label for="password">Пароль</label>
                    <input class="form-control" type="password" required id="password" placeholder="Введите Ваш пароль" v-model="form.password">
                </div>

                <div class="form-group mb-0 text-center">
                    <button v-if="!loading.login" class="btn btn-primary" type="submit" @click.prevent="submitForm()"> Войти </button>
                    <button v-else class="btn btn-primary" type="button" disabled>
                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        Загрузка...
                    </button>
                </div>

                <hr>

                <button v-if="!loading.gauth" class="btn btn-block btn-outline-dark" @click="oauth_login('google')">
                    <img width="20px" style="margin-bottom:3px; margin-right:5px" alt="Google sign-in" src="@/assets/images/google-logo.webp" />
                    Login with Google
                </button>
                <button v-else class="btn btn-block btn-outline-dark" type="button" disabled>
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Загрузка...
                </button>
            </form>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12 text-center">
            <p class="text-muted">Нет аккаунта? <router-link :to="{name: 'Signup'}" class="text-muted ml-1"><b>Регистрация</b></router-link></p>
        </div> <!-- end col -->
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import log from '@/plugins/log'
import { to } from '@/plugins/utils'

export default {
	name: 'Setup',
    data: () => {
        return {
            form: {
                email: '',
                password: ''
            },
            loading: {
                login: false,
                gauth: false
            }
        }
    },
    computed: {
        ...mapState({
			user: state => state.auth.user,
			settings: state => state.settings,
		}),
    },
    methods: {
        async submitForm() {
            if(this.loading.login) return $.NotificationApp.send("Ошибка", "Пожалуйста подождите", "top-right", "", "error")

            if(!this.form.email) return $.NotificationApp.send("Ошибка", "Пожалуйста введите адрес электронной почты", "top-right", "", "error")
            if(!this.form.password) return $.NotificationApp.send("Ошибка", "Пожалуйста введите пароль", "top-right", "", "error")

            this.loading.login = true

            var [err, response] = await to( this.$http.post('/auth/login', this.form) )
            if(err) {
                this.loading.login = false
                log.error('/views/empty/Login', 'methods.submitForm', '$http.request', err)
                return $.NotificationApp.send("Ошибка", err.message, "top-right", "", "error")
            }
            if(!response.data.success) {
                this.loading.login = false
                log.error('/views/empty/Login', 'methods.submitForm', '$http.response', response.data)
                return $.NotificationApp.send("Ошибка", response.data.error, "top-right", "", "error")
            }
            this.loading.login = false

            await this.$store.dispatch('auth/parseAuthResponse', [err, response]);
			window.location = '/project/list';
        },
        oauth_login(name) {
            this.loading.gauth = true

            const url = `${this.settings.backend_url}/oauth/${name}`;
            const win = window.open(url, 'Login', 'height=500,width=400');

            const check = setInterval(async () => {
                if(win.closed === true) {
                    clearInterval(check)

                    await this.$store.dispatch('auth/refresh_token');
                    await this.$store.dispatch('auth/get_user', this.$orm);

                    this.loading.gauth = false

                    let authenticated = this.$store.getters['auth/authenticated']

                    if(authenticated) 
                        window.location = '/project/list';
                    else
                        $.NotificationApp.send("Ошибка", "Не удалось авторизоваться", "top-right", "", "error")
                }
            }, 1000)
        }
    }
};
</script>
